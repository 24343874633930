import {graphql, Link} from 'gatsby';
import React from 'react';

import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="From product to self-sufficient software business – Step 5 of our approach"
        description="Software products that we develop must be able to outgrow our services. That is why we help with setting up an internal team at our client's."
        ogImage="capability-transfer-en.png"
        translatedPagePath="/nl/werkwijze/capability-transfer/"
        locale={locale}>
        <Link
            to="/en/approach/continuous-development/"
            className="body__nav body__nav--left"
            title="Approach step 4B. Further development">
            Previous step
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            From product <br /> to self-sufficient <br /> software business
                        </h2>
                        <div className="display__number">5</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Products we develop need to be able to outgrow our services. That’s why we help to set up
                            the client’s own internal product development team.
                        </p>
                        <p>
                            We start a development <span className="highlight">transition process</span> in consultation
                            with the client. In this process we work with our client to put together an internal product
                            team. This team will ultimately carry out continuous development and manage the production
                            environment itself.
                        </p>
                        <p>
                            We screen candidates for the client and allow them to work with us at our offices during the
                            continuous development of the product. They gain practical experience of all aspects of our
                            methods and processes. So over time the candidates acquire all the skills and experience
                            they need to take over <span className="highlight">responsibility</span> for the development
                            process.
                        </p>
                        <p>
                            After a transition period, the client has a skilled internal development team enabling the
                            organisation to become a <span className="highlight">self-sufficient</span> software
                            business.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Entirely optional</li>
                            <li className="ion-md-arrow-dropright">Capability transfer</li>
                            <li className="ion-md-arrow-dropright">Minimum of 3 candidates</li>
                            <li className="ion-md-arrow-dropright">At least 3 months per candidate</li>
                        </ul>
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
